import React from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import { ResourcesList } from "../components/sections/resources-list/resources-list"

export default () => {
  return (
    <Layout>
      <HeaderBanner title="White Papers & Use Cases" />
      <div
        style={{
          margin: "86px 0px",
          padding: "0px 20px",
        }}
      >
        <Breadcrumb
          firstLink="/white-papers-use-cases"
          firstLinkTitle="White Papers & Use Cases"
        />
      </div>
      <ResourcesList />
      <CTABanner
        tagline="Step Into The Future"
        CTA="video"
        CTADetail="how video"
      />
    </Layout>
  )
}
